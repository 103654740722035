import { DropdownItem } from '@keplerco/core';
import { UniqueEntity } from '../../models';

export function generateDropdownItemsFromUniqueEntities(entities: UniqueEntity[] | undefined, onClick: (value: string) => void): DropdownItem[] {
  const items: DropdownItem[] = [];

  entities?.forEach(entity => {
    if (!items.some(option => option.value === entity.slug)) items.push({ label: entity.name, value: entity.slug!, onClick: () => onClick(entity.slug!) });
  });

  return items;
}

