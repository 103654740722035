import { ActionCard, Anchor, CascadingPanel, CascadingPanels, Chip, KebabMenu, ListItemLayout, ListLayout, useCascadingPanelsConnector } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { AstronautsIcon } from '../../../widgets/forms/skill-assessment/people-and-skills/astronauts.icon';
import { IPerson } from '../../../widgets/forms/skill-assessment/people-and-skills/people-and-skills.models';
import { AssessmentPreferencesResponse } from '../../../models/overmind/assessment-preferences';
import classNames from 'classnames';
import { useAppActions, useAppState } from '../../../overmind';
import { EditPeopleLayout } from '../../../widgets/forms/skill-assessment/people-and-skills/edit-people.layout';
import { EditPersonLayout } from '../../../widgets/forms/skill-assessment/people-and-skills/edit-person.layout';
import { ImportSkillLayout } from '../../administration/roles/manage-role/import-skill.layout';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { IChip } from '../../../components/general/entity-selection/entity-selection.models';
import { EmptyState } from '../../../components/general/empty-state/empty-state';

enum CascadingFocusPanelIds {
  EditPeople = 'EditPeople',
  EditPerson = 'EditPerson',
  ImportSkills = 'ImportSkills',
}

enum ErrorMessage {
  // Manager Review
  ManagerReview = 'A manager must be selected.',
  ManagerReviewPeople = 'A non-manager must be selected.',
  ManagerReviewSkills = 'All selected non-managers must have at least one skill assigned.',

  // Peer Review
  PeerReviewPeople = 'At least two people must be selected.',
  PeerReviewSkills = 'All selected people must have at least one skill assigned.',

  // Self-Review
  SelfReviewPeople = 'At least one person must be selected.',
  SelfReviewSkills = 'All selected people must have at least one skill assigned.',
}

export function AssessmentsWizardPeopleAndSkillsWidget({ assessmentSlug, preferences, onSaveAndContinue }: { assessmentSlug: string | undefined; preferences: AssessmentPreferencesResponse; onSaveAndContinue: () => void }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const { openPanelIds, next, previous } = useCascadingPanelsConnector();

  const [loading, setLoading] = useState<boolean>(false);
  const [initialPeople, setInitialPeople] = useState<IPerson[]>([]);
  const [people, setPeople] = useState<IPerson[]>([]);
  const [peopleToEdit, setPeopleToEdit] = useState<IPerson[]>();
  const [personToEdit, setPersonToEdit] = useState<IPerson>();
  const [sync, setSync] = useState<string>(crypto.randomUUID());
  const [dirty, setDirty] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  useEffect(() => {
    async function getData() {
      setLoading(true);

      const peopleResponse = await actions.searchPeople({
        companySlug: companyVariables.slug,
        page: 1,
        pageSize: 99999, // TODO: update BE to send required information to remove ridiculously large pageSize to get ALL People
      });
      if (!assessmentSlug) return;

      const assigneesResponse = await actions.getSkillAssessmentAssignees({ companySlug: companyVariables.slug!, skillAssessmentSlug: assessmentSlug });

      const data: IPerson[] = [];
      assigneesResponse?.assignees?.forEach(assignee => {
        const person = peopleResponse?.employees.find(employee => employee.id === assignee.userId);
        if (!person) return;
        data.push({
          ...person,
          selected: true,
          manager: person.id === assigneesResponse.assignedTeamChampionId,
          skills:
            assignee.skills?.map(skill => ({
              slug: skill.companySkillId.toString(),
              name: skill.skillName,
              description: skill.skillDescription,
              level: skill.skillLevel,
              companyEntityId: skill.companySkillId,
              assignedFrom: '',
              dateCreated: '',
            })) ?? [],
        });
      });
      setInitialPeople(data);
      setPeople(data);

      setLoading(false);
    }

    getData();
  }, []);

  useEffect(() => {
    if (preferences.allowManagerReview) {
      const manager = people.find(person => person.manager);

      if (!manager) {
        return setErrorMessage(ErrorMessage.ManagerReview);
      }

      if (people.length < 2) {
        return setErrorMessage(ErrorMessage.ManagerReviewPeople);
      }
      if (people.some(person => !person.manager && !person.skills.length)) {
        return setErrorMessage(ErrorMessage.ManagerReviewSkills);
      }
    }

    if (preferences.allowPeerReview) {
      if (people.length < 2) {
        return setErrorMessage(ErrorMessage.PeerReviewPeople);
      }

      if (people.some(person => !preferences.allowManagerReview && !person.skills.length)) {
        return setErrorMessage(ErrorMessage.PeerReviewSkills);
      }
    }

    if (preferences.allowSelfReview) {
      if (!people.length) {
        return setErrorMessage(ErrorMessage.SelfReviewPeople);
      }

      if (people.some(person => !preferences.allowManagerReview && !person.skills.length)) {
        return setErrorMessage(ErrorMessage.SelfReviewSkills);
      }
    }

    setErrorMessage(undefined);
  }, [people, preferences]);

  function onCloseEditPeople() {
    setPeopleToEdit(undefined);
    previous();
  }

  function onCloseEditPerson() {
    setPersonToEdit(undefined);
    previous();
  }

  function onCloseImportSkills() {
    setSync(crypto.randomUUID());
    previous();
  }

  function generateChips(person: IPerson): IChip[] {
    let chips: IChip[] = [
      {
        label: `${person.skills.length} skill(s)`,
        backgroundColour: 'g',
      },
    ];
    if (preferences.allowManagerReview && !preferences.allowPeerReview && person.manager) {
      chips = [];
    }
    if (person.manager) {
      chips.unshift({
        label: 'Manager',
        backgroundColour: 'baby-blue',
      });
    }

    return chips;
  }

  if (loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        <SkeletonLoader height="135px" />

        <SkeletonLoader width="250px" height="20px" />

        <SkeletonLoader height="400px" />

        <div style={{ marginTop: 45, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <SkeletonLoader width="150px" height="25px" />
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
      <ActionCard
        icon={<AstronautsIcon />}
        title="Select individuals for assessment"
        description="Choose the individuals you want to assess from the company. Ensure that the selected people are aligned with the goals of the assessment."
        action={{
          type: 'button',
          text: 'Assign people',
          onClick: () => {
            setPeopleToEdit(people);
            next(CascadingFocusPanelIds.EditPeople);
          },
        }}
      />

      <div className="heading5">Assigned People and Skills:</div>

      <div style={{ maxHeight: 400, overflowY: 'auto' }}>
        {!!people.length ? (
          <ListLayout>
            {people.map(person => (
              <ListItemLayout key={person.id}>
                <div className="card" style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: 'auto 1fr 1fr 195px' }}>
                  <KebabMenu
                    items={[
                      {
                        label: 'Remove person',
                        onClick: () => {
                          setPeople(currentState => currentState.filter(temp => temp.id !== person.id));
                        },
                      },
                      {
                        label: 'Edit skills',
                        onClick: () => {
                          setPersonToEdit(person);
                          next(CascadingFocusPanelIds.EditPerson);
                        },
                      },
                    ]}
                  />

                  <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                    {person.firstName} {person.lastName}
                  </div>

                  <div className="caption2">{person.email}</div>

                  <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 15 }}>
                    {generateChips(person).map(chip => (
                      <Chip key={chip.label} {...chip} />
                    ))}
                  </div>
                </div>
              </ListItemLayout>
            ))}
          </ListLayout>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <EmptyState title="No people assigned" subtitle="Please select people to assign" />
          </div>
        )}
      </div>

      <div className={classNames('formErrorMessage', { invisible: !dirty || !errorMessage })}>{errorMessage}&nbsp;</div>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Anchor
          arrow
          onClick={async () => {
            setDirty(true);

            if (!assessmentSlug) return;
            if (!!errorMessage) return;

            setLoading(true);

            // TODO: refactor APIs to simplify FE code
            await actions.postAssessmentPeople({
              assessmentSlug,
              assignedTeamChampionId: people.find(person => person.manager)?.id,
              userIds: people.map(person => person.id),
            });

            const peopleAdded: IPerson[] = people.filter(person => !initialPeople.some(initialPerson => initialPerson.id === person.id));
            const peopleRemoved: IPerson[] = [];
            const peopleUpdated: IPerson[] = [];
            initialPeople.forEach(initialPerson => (!people.some(person => person.id === initialPerson.id) ? peopleRemoved.push(initialPerson) : peopleUpdated.push(initialPerson)));

            const skillsToAdd: { skillId: number; userId: string }[] = [];
            const skillsToRemove: { skillId: number; userId: string }[] = [];

            peopleAdded.forEach(personAdded => {
              personAdded.skills.forEach(skill => skillsToAdd.push({ skillId: skill.companyEntityId!, userId: personAdded.id }));
            });
            peopleRemoved.forEach(personRemoved => {
              personRemoved.skills.forEach(skill => skillsToRemove.push({ skillId: skill.companyEntityId!, userId: personRemoved.id }));
            });
            peopleUpdated.forEach(initialPerson => {
              const currentPerson = people.find(person => person.id === initialPerson.id);
              if (!currentPerson) return;

              // remove skills on the initial person that aren't on the current person
              initialPerson.skills.forEach(initialSkill => {
                if (!currentPerson.skills.some(currentSkill => currentSkill.companyEntityId === initialSkill.companyEntityId)) {
                  skillsToRemove.push({ skillId: initialSkill.companyEntityId!, userId: initialPerson.id });
                }
              });

              // add skills on the current person that aren't on the initial person
              currentPerson.skills.forEach(currentSkill => {
                if (!initialPerson.skills.some(initialSkill => initialSkill.companyEntityId === currentSkill.companyEntityId)) {
                  skillsToAdd.push({ skillId: currentSkill.companyEntityId!, userId: initialPerson.id });
                }
              });
            });

            for (const skillToAdd of skillsToAdd) {
              await actions.addSkillToFocusArea({
                ...skillToAdd,
                assessmentSlug,
              });
            }

            for (const skillToRemove of skillsToRemove) {
              await actions.removeSkillFromFocusArea({
                ...skillToRemove,
                assessmentSlug,
              });
            }

            setLoading(false);

            onSaveAndContinue();
          }}
        >
          Save & Continue
        </Anchor>
      </div>

      <CascadingPanels
        openPanelIds={openPanelIds}
        onClosePanel={id => {
          if (id === CascadingFocusPanelIds.EditPeople) {
            onCloseEditPeople();
          } else if (id === CascadingFocusPanelIds.EditPerson) {
            onCloseEditPerson();
          } else if (id === CascadingFocusPanelIds.ImportSkills) {
            onCloseImportSkills();
          }
        }}
      >
        <CascadingPanel id={CascadingFocusPanelIds.EditPeople}>
          {!!peopleToEdit && (
            <EditPeopleLayout
              peopleToEdit={peopleToEdit}
              setPeopleToEdit={setPeopleToEdit}
              setPersonToEdit={person => {
                setPersonToEdit(person);
                next(CascadingFocusPanelIds.EditPerson);
              }}
              allowManagerReview={!!preferences.allowManagerReview}
              allowPeerReview={!!preferences.allowPeerReview}
              onBack={onCloseEditPeople}
              onDone={() => {
                setPeople(peopleToEdit);
                onCloseEditPeople();
              }}
            />
          )}
        </CascadingPanel>

        <CascadingPanel id={CascadingFocusPanelIds.EditPerson}>
          {!!personToEdit && (
            <EditPersonLayout
              personToEdit={personToEdit}
              sync={sync}
              allowManagerReview={!!preferences.allowManagerReview}
              allowPeerReview={!!preferences.allowPeerReview}
              onImport={() => next(CascadingFocusPanelIds.ImportSkills)}
              onBack={onCloseEditPerson}
              onDone={person => {
                !peopleToEdit
                  ? setPeople(currentState => {
                    const nextState: IPerson[] = structuredClone(currentState);
                    nextState.forEach(temp => {
                      if (temp.id === person.id) {
                        temp.manager = person.manager;
                        temp.skills = preferences.allowManagerReview && !preferences.allowPeerReview && person.manager ? [] : person.skills;
                        return;
                      }

                      temp.manager = person.manager ? false : temp.manager;
                    });
                    return nextState;
                  })
                  : setPeopleToEdit(currentState => {
                    if (!currentState) return;
                    const nextState: IPerson[] = structuredClone(currentState);
                    nextState.forEach(temp => {
                      if (temp.id === person.id) {
                        temp.manager = person.manager;
                        temp.skills = preferences.allowManagerReview && !preferences.allowPeerReview && person.manager ? [] : person.skills;
                        return;
                      }

                      temp.manager = person.manager ? false : temp.manager;
                    });
                    return nextState;
                  });
                onCloseEditPerson();
              }}
            />
          )}
        </CascadingPanel>

        <CascadingPanel id={CascadingFocusPanelIds.ImportSkills}>
          <ImportSkillLayout supertitle="Assessments" onClose={onCloseImportSkills} />
        </CascadingPanel>
      </CascadingPanels>
    </div>
  );
}
