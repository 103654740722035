import * as base from '../base';
import { BaseSearchRequest } from '../../../models/overmind/search-request';
import { EmployeeSearchResponse } from '../../../models/view/employee-search-response';
import { SavableEmployee, Employee } from '../../../models/view/employee';
import { Context } from '../..';
import { CompanyUserFileUpload } from '../../../models/overmind/company-user-file-upload';
import { CsvEmployeeResponse } from '../../../models/view/csv-employee-response';
import { ActivationStatus } from '../../../enums/activation-status';
import { UserBulkSaveDto, UserBulkValidationDto } from '../../../models/user-bulk-save';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';

export async function getEmployeesCountByCompanySlugAndActivationStatus(context: Context, params: { companySlug: string; activationStatus: ActivationStatus }): Promise<number | undefined> {
  const request: base.IRequest = { url: base.url('people', 'get-employees-by-activation-status-count', params.companySlug, params.activationStatus.toString()), authenticated: true };
  const response: base.IResponse<number> = await base.request(request);

  return response.data;
}

export async function getEmployeesByCompanySlugAndActivationStatus(context: Context, params: { companySlug: string; activationStatus: ActivationStatus }): Promise<Employee[] | undefined> {
  const request: base.IRequest = { url: base.url('people', 'get-employees-by-activation-status', params.companySlug, params.activationStatus.toString()), authenticated: true };
  const response: base.IResponse<Employee[]> = await base.request(request);

  return response.data;
}

export async function getEmployeesPendingInvitesCount(context: Context, companySlug: string): Promise<number | undefined> {
  const request: base.IRequest = { url: base.url(`people`, `get-employees-pending-invites-count`, companySlug), authenticated: true };
  const response: base.IResponse<number> = await base.request(request);

  return response.data;
}

export async function getEmployeesPendingInvites(context: Context, companySlug: string): Promise<Employee[] | undefined> {
  const request: base.IRequest = { url: base.url(`people`, `get-employees-pending-invites`, companySlug), authenticated: true };
  const response: base.IResponse<Employee[]> = await base.request(request);

  return response.data;
}

export async function archivePerson(context: Context, userId: string): Promise<void> {
  const request: base.IRequest = { url: base.url(`people`, `archive-employee`, userId), authenticated: true, method: 'PUT' };
  await base.request(request);
}

export async function bulkArchivePeople(context: Context, userIds: string[]): Promise<void> {
  const request: base.IRequest = { url: base.url(`people`, `bulk-archive-employee`), authenticated: true, method: 'PUT', body: JSON.stringify(userIds) };
  await base.request(request);
}

export async function unarchivePerson(context: Context, userId: string): Promise<void> {
  const request: base.IRequest = { url: base.url(`people`, `un-archive-employee`, userId), authenticated: true, method: 'POST' };
  await base.request(request);
}

export async function bulkUnarchivePeople(context: Context, userIds: string[]): Promise<void> {
  const request: base.IRequest = { url: base.url(`people`, `bulk-un-archive-employee`), authenticated: true, method: 'POST', body: JSON.stringify(userIds) };
  await base.request(request);
}

export async function uploadEmployeesCsv(context: Context, companyUserFileUpload: CompanyUserFileUpload): Promise<CsvEmployeeResponse[] | undefined> {
  const formData: FormData = new FormData();
  formData.append('File', companyUserFileUpload.file);
  formData.append('AddToAssessment', companyUserFileUpload.addToAssessment ? 'true' : 'false');

  const request: base.IRequest = { url: base.url(`people`, `upload-employees`, companyUserFileUpload.companySlug), authenticated: true, method: 'POST', headers: { Accept: 'multipart/form-data' }, body: formData };
  const response: base.IResponse<CsvEmployeeResponse[]> = await base.request(request);

  return response.data;
}

export async function searchPeople(context: Context, searchRequest: BaseSearchRequest): Promise<EmployeeSearchResponse | undefined> {
  const request: base.IRequest = { url: base.url(`people`, `search-employees`, `${searchRequest.companySlug}${base.params(searchRequest)}`), authenticated: true };
  const response: base.IResponse<EmployeeSearchResponse> = await base.request(request);

  return response.data;
}

export async function getPerson(context: Context, id: string): Promise<Employee | undefined> {
  const request: base.IRequest = { url: base.url(`people`, `get-employee`, id), authenticated: true };
  const response: base.IResponse<Employee> = await base.request(request);

  return response.data;
}

export async function savePerson(context: Context, company: SavableEmployee): Promise<Employee | undefined> {
  const request: base.IRequest = { url: base.url(`people`, `save-employee`, company.companySlug!), authenticated: true, method: 'POST', body: JSON.stringify(company) };
  const response: base.IResponse<Employee> = await base.request(request);

  if (!!response.error) {
    context.actions.addNotification({
      id: Date.now().toString(),
      title: 'Error',
      message: response.error.detail,
      priority: NotificationPriority.Toast,
      type: NotificationType.Error,
      active: true,
    });
    return undefined;
  }

  context.actions.addNotification({
    id: Date.now().toString(),
    title: `Person ${!!response.data?.id ? 'updated' : 'saved'}`,
    message: `${response.data?.firstName} has successfully been ${!!response.data?.id ? 'updated' : 'saved'}`,
    priority: NotificationPriority.Toast,
    type: NotificationType.Success,
    active: true,
  });
  return response.data;
}

export async function validateUsersUploadCsv(context: Context, companyUserFileUpload: CompanyUserFileUpload): Promise<UserBulkValidationDto[] | undefined> {
  const formData: FormData = new FormData();
  formData.append('File', companyUserFileUpload.file);

  const request: base.IRequest = {
    url: base.url(`people`, `validate-users-upload-csv`, companyUserFileUpload.companySlug),
    authenticated: true,
    method: 'POST',
    headers: { Accept: 'multipart/form-data' },
    body: formData,
  };

  const response: base.IResponse<UserBulkValidationDto[]> = await base.request(request);

  return response.data;
}

export async function uploadEmployees(context: Context, companyUserFileUpload: CompanyUserFileUpload): Promise<CsvEmployeeResponse[] | undefined> {
  const formData: FormData = new FormData();
  formData.append('File', companyUserFileUpload.file);
  formData.append('AddToAssessment', companyUserFileUpload.addToAssessment ? 'true' : 'false');

  const request: base.IRequest = { url: base.url(`people`, `upload-employees`, companyUserFileUpload.companySlug), authenticated: true, method: 'POST', headers: { Accept: 'multipart/form-data' }, body: formData };
  const response: base.IResponse<CsvEmployeeResponse[]> = await base.request(request);

  return response.data;
}

export async function uploadEmployeesData(context: Context, params: { companySlug: string; employeesData: UserBulkSaveDto[] }): Promise<UserBulkValidationDto[] | undefined> {
  const request: base.IRequest = {
    url: base.url(`people`, `upload-employees`, params.companySlug),
    authenticated: true,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params.employeesData),
  };

  const response: base.IResponse<UserBulkValidationDto[]> = await base.request(request);

  return response.data;
}
