import { Context } from '../..';
import * as base from '../base';
import { AssessmentsSearchRequest, BaseSearchRequest } from '../../../models/overmind/search-request';
import { SkillAssessmentsSearchResponse } from '../../../models/view/skill-assessments-search-response';
import { SkillAssessment } from '../../../models/view/skill-assessment';
import { SkillAssessmentAssigneeStatusResponse } from '../../../models/skill-assessment-config';
import { UniqueEntity } from '../../../models/view/unique-entity';
import { UserAssessmentStatusList } from '../../../models/view/user-assessment-status-list';
import { SkillAssessmentAssignees } from '../../../models/view/skill-assessment-assignee';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';
import { AssessmentPreferencesRequest, AssessmentPreferencesResponse } from '../../../models/overmind/assessment-preferences';
import { AssessmentsWizardDetails, AssessmentWizardPeople } from '../../../pages/assessments/wizard/assessments-wizard-peer.page';
import { AssessmentsWizardQuestionnaires } from '../../../pages/assessments/wizard/assessments-wizard-questionnaires.widget';
import { SkillAssessmentAssignee } from '../../../enums';
import { AssessmentsWizardDepartments, AssessmentsWizardTeams } from '../../../pages/assessments/wizard/assessments-wizard-entities.widget';
import { AssessmentsWizardFieldsOfPractice } from '../../../pages/assessments/wizard/assessments-wizard-fop.widget';

export async function addSkillToFocusArea(context: Context, params: { assessmentSlug: string; skillId: number; userId?: string; teamSlug?: string }): Promise<base.IResponse<any>> {
  const queryParams = new URLSearchParams();
  if (params.userId) queryParams.append('userId', params.userId);
  if (params.teamSlug) queryParams.append('teamSlug', params.teamSlug);

  const request: base.IRequest = {
    url: base.url(`assessment`, `add-skill-to-focus-area`, params.assessmentSlug, params.skillId.toString()) + (queryParams.toString() ? '?' + queryParams.toString() : ''),
    method: 'POST',
    authenticated: true,
  };

  return await base.request(request);
}

export async function getAssessmentPreferences(context: Context, payload: AssessmentPreferencesRequest): Promise<AssessmentPreferencesResponse | undefined> {
  const request: base.IRequest = { url: base.url(`assessment`, `preferences`, payload.companySlug, payload.assessmentSlug), authenticated: true };
  const response: base.IResponse<AssessmentPreferencesResponse> = await base.request(request);
  return response.data;
}

export async function updateAssessmentPreferences(context: Context, payload: AssessmentPreferencesRequest & AssessmentPreferencesResponse): Promise<AssessmentPreferencesResponse | undefined> {
  const request: base.IRequest = { url: base.url(`assessment`, `preferences`, payload.companySlug, payload.assessmentSlug), method: 'PUT', authenticated: true, body: JSON.stringify(payload) };
  const response: base.IResponse<AssessmentPreferencesResponse> = await base.request(request);
  return response.data;
}

export async function completeEndorsementSkillAssignment(context: Context, params: { assessmentSlug: string }): Promise<void> {
  const request: base.IRequest = {
    url: base.url(`assessment`, `complete-endorsement-skill-assignment`, params.assessmentSlug),
    method: 'POST',
    authenticated: true,
  };

  await base.request(request);
}

export async function getActiveSkillAssessment(context: Context, companySlug: string): Promise<SkillAssessment | undefined> {
  const request: base.IRequest = { url: base.url(`assessment`, `get-active-assessment`, companySlug), authenticated: true };
  const response: base.IResponse<SkillAssessment> = await base.request(request);

  return response.data;
}

export async function getSkillAssessments(context: Context, SearchRequest: AssessmentsSearchRequest): Promise<SkillAssessmentsSearchResponse | undefined> {
  if (SearchRequest.completionStatus === undefined) delete SearchRequest.completionStatus;
  const queryParams = new URLSearchParams(SearchRequest as any).toString();

  const request: base.IRequest = { url: base.url(`assessment`, `get-assessments`, `${SearchRequest.companySlug}?${queryParams}`), authenticated: true };
  const response: base.IResponse<SkillAssessmentsSearchResponse> = await base.request(request);

  return response.data;
}

export async function updateSkillAssessment(context: Context, skillAssessment: SkillAssessment): Promise<SkillAssessment | undefined> {
  const request: base.IRequest = { url: base.url(`assessment`, `update-assessment`, skillAssessment.companySlug), authenticated: true, method: 'POST', body: JSON.stringify(skillAssessment) };
  const response: base.IResponse<SkillAssessment> = await base.request(request);

  return response.data;
}

export async function completeSkillAssessment(context: Context, params: { companySlug: string; skillAssessmentSlug: string }): Promise<SkillAssessment | undefined> {
  const request: base.IRequest = { url: base.url(`assessment`, `complete-active-assessment`, params.companySlug, params.skillAssessmentSlug), authenticated: true, method: 'POST' };
  const response: base.IResponse<SkillAssessment> = await base.request(request);

  return response.data;
}

export async function archiveAssessment(context: Context, params: { assessmentSlug: string }): Promise<void> {
  const request: base.IRequest = { url: base.url(`assessment`, `archive-assessment`, params.assessmentSlug), method: 'POST', authenticated: true };
  await base.request(request);
}

export async function activateSkillAssessment(context: Context, payload: { companySlug: string; assessmentSlug: string }): Promise<SkillAssessment | undefined> {
  const request: base.IRequest = { url: base.url(`assessment`, `activate-assessment`, payload.companySlug, payload.assessmentSlug), authenticated: true, method: 'POST' };
  const response: base.IResponse<SkillAssessment> = await base.request(request);

  return response.data;
}

export async function saveSkillAssessmentAssignees(context: Context, params: { assessmentAssignee: SkillAssessmentAssignees; companySlug: string }): Promise<SkillAssessmentAssignees | undefined> {
  const request: base.IRequest = { url: base.url(`assessment`, `save-assessment-assignees`, params.companySlug, params.assessmentAssignee.assessmentSlug), authenticated: true, method: 'POST', body: JSON.stringify(params.assessmentAssignee) };
  const response: base.IResponse<SkillAssessmentAssignees> = await base.request(request);

  return response.data;
}

export async function resetAssessment(context: Context, params: { assessmentSlug: string; userIds: string[] }): Promise<void> {
  const request: base.IRequest = {
    url: base.url(`assessment`, `reset-assessment`, params.assessmentSlug),
    authenticated: true,
    method: 'POST',
    body: JSON.stringify(params.userIds),
  };
  await base.request(request);
}

export async function getSkillAssessmentAssignees(context: Context, params: { companySlug: string; skillAssessmentSlug: string }): Promise<SkillAssessmentAssignees | undefined> {
  const request: base.IRequest = { url: base.url(`assessment`, `get-assessment-assignees`, params.companySlug, params.skillAssessmentSlug), authenticated: true };
  const response: base.IResponse<SkillAssessmentAssignees> = await base.request(request);

  return response.data;
}

export async function getUserStatusList(context: Context, props: { body: BaseSearchRequest; skillAssessmentSlug: string }): Promise<SkillAssessmentAssigneeStatusResponse | undefined> {
  const params = new URLSearchParams(Object.entries(props.body));

  const request: base.IRequest = { url: base.url(`assessment`, `get-user-status-list`, `${props.skillAssessmentSlug}?${params.toString()}`), authenticated: true, method: 'GET' };
  const response: base.IResponse<SkillAssessmentAssigneeStatusResponse> = await base.request(request);

  return response.data;
}

export async function getSkillAssessment(context: Context, payload: { companySlug: string; assessmentSlug: string }): Promise<SkillAssessment | undefined> {
  const request: base.IRequest = { url: base.url(`assessment`, `get-assessment`, payload.companySlug, payload.assessmentSlug), authenticated: true };
  const response: base.IResponse<SkillAssessment> = await base.request(request);

  return response.data;
}

// manager assessments focus panels
export async function getActiveSkillAssessmentList(context: Context, companySlug: string): Promise<UniqueEntity[] | undefined> {
  const request: base.IRequest = { url: base.url('assessment', 'get-active-assessment-list', companySlug), authenticated: true };
  const response: base.IResponse<UniqueEntity[]> = await base.request(request);

  return response.data;
}

export async function getUserSkillAssessmentStatusList(context: Context, assessmentSlug: string): Promise<UserAssessmentStatusList | undefined> {
  const request: base.IRequest = { url: base.url('assessment', 'get-user-status-list', `${assessmentSlug}?pageSize=1000&page=1`), authenticated: true };
  const response: base.IResponse<UserAssessmentStatusList> = await base.request(request);

  return response.data;
}

export async function markSingleLearnerAsCompleteForSkillAssessment(context: Context, props: { assessmentSlug: string; userId: string }): Promise<UserAssessmentStatusList | undefined> {
  const request: base.IRequest = { url: base.url('assessment', 'complete-learner-assessment', props.assessmentSlug, props.userId), authenticated: true, method: 'POST' };
  const response = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      title: `Couldn't mark learner as complete`,
      message: `We were unable to mark this learner as complete`,
      active: true,
      id: `${props.userId}_${Date.now()}`,
      priority: NotificationPriority.Toast,
      type: NotificationType.Error,
    });
  }

  return response.data;
}

export async function downloadSkillAssessmentAssigneeReportCSV(_: Context, assessmentSlug: string): Promise<void> {
  const request: base.IRequest = { url: base.url('assessment', 'download-assessment-learners-list', assessmentSlug), authenticated: true, method: 'POST' };
  const response = await base.request(request);
  const csvRaw = response.data as string;
  const blob = new Blob([csvRaw], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = `${assessmentSlug}.csv`;
  setTimeout(() => anchor.click());
}

export async function removeSkillFromFocusArea(context: Context, params: { assessmentSlug: string; skillId: number; userId?: string; teamSlug?: string }): Promise<void> {
  const queryParams = new URLSearchParams();
  if (params.userId) queryParams.append('userId', params.userId);
  if (params.teamSlug) queryParams.append('teamSlug', params.teamSlug);

  const request: base.IRequest = {
    url: base.url(`assessment`, `remove-skill-from-focus-area`, params.assessmentSlug, params.skillId.toString()) + (queryParams.toString() ? '?' + queryParams.toString() : ''),
    method: 'POST',
    authenticated: true,
  };

  await base.request(request);
}

// refactored
export async function postAssessmentDetails(context: Context, payload: AssessmentsWizardDetails): Promise<string | undefined> {
  const url = base.url(`assessment`, `update-assessment`, payload.companySlug);
  const request: base.IRequest = { url, method: 'POST', body: JSON.stringify(payload) };
  const response: base.IResponse<SkillAssessment> = await base.request(request);
  return response.data?.slug;
}

export async function putAssessmentPreferences(context: Context, payload: AssessmentPreferencesRequest & AssessmentPreferencesResponse): Promise<void> {
  const url = base.url(`assessment`, `preferences`, payload.companySlug, payload.assessmentSlug);
  const request: base.IRequest = { url, method: 'PUT', body: JSON.stringify(payload) };
  await base.request(request);
}

export async function postAssessmentPeople(context: Context, payload: AssessmentWizardPeople): Promise<SkillAssessmentAssignees | undefined> {
  const url = base.url(`assessment`, `save-assessment-assignees`, context.state.companyVariables.slug!, payload.assessmentSlug);
  const body = JSON.stringify({ ...payload, assessmentGroup: SkillAssessmentAssignee.Learner });
  const request: base.IRequest = { url, method: 'POST', body };
  const response: base.IResponse<SkillAssessmentAssignees> = await base.request(request);
  return response.data;
}

export async function postAssessmentQuestionnaires(context: Context, payload: AssessmentsWizardQuestionnaires): Promise<string | undefined> {
  const url = base.url(`assessment`, `update-assessment`, payload.companySlug);
  const request: base.IRequest = { url, method: 'POST', body: JSON.stringify(payload) };
  const response: base.IResponse<SkillAssessment> = await base.request(request);
  return response.data?.slug;
}

export async function postAssessmentTeams(context: Context, payload: AssessmentsWizardTeams): Promise<SkillAssessmentAssignees | undefined> {
  const url = base.url(`assessment`, `save-assessment-assignees`, context.state.companyVariables.slug!, payload.assessmentSlug);
  const body = JSON.stringify({ ...payload, assessmentGroup: SkillAssessmentAssignee.Team });
  const request: base.IRequest = { url, method: 'POST', body };
  const response: base.IResponse<SkillAssessmentAssignees> = await base.request(request);
  return response.data;
}

export async function postAssessmentDepartments(context: Context, payload: AssessmentsWizardDepartments): Promise<SkillAssessmentAssignees | undefined> {
  const url = base.url(`assessment`, `save-assessment-assignees`, context.state.companyVariables.slug!, payload.assessmentSlug);
  const body = JSON.stringify({ ...payload, assessmentGroup: SkillAssessmentAssignee.Department });
  const request: base.IRequest = { url, method: 'POST', body };
  const response: base.IResponse<SkillAssessmentAssignees> = await base.request(request);
  return response.data;
}

export async function postAssessmentCompany(context: Context, payload: string): Promise<SkillAssessmentAssignees | undefined> {
  const url = base.url(`assessment`, `save-assessment-assignees`, context.state.companyVariables.slug!, payload);
  const body = JSON.stringify({ assessmentGroup: SkillAssessmentAssignee.Company });
  const request: base.IRequest = { url, method: 'POST', body };
  const response: base.IResponse<SkillAssessmentAssignees> = await base.request(request);
  return response.data;
}

export async function postAssessmentFieldsOfPractice(context: Context, payload: AssessmentsWizardFieldsOfPractice): Promise<string | undefined> {
  const url = base.url(`assessment`, `update-assessment`, payload.companySlug);
  const request: base.IRequest = { url, method: 'POST', body: JSON.stringify(payload) };
  const response: base.IResponse<SkillAssessment> = await base.request(request);
  return response.data?.slug;
}
