import { Anchor, Button, Chip, FocusPanelHeader } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../../overmind';
import { IEditPeopleLayoutProps, IPerson } from './people-and-skills.models';
import { BaseSearchRequest, EmployeeSearchResponse } from '../../../../models';
import { IChip } from '../../../../components/general/entity-selection/entity-selection.models';
import { SearchList } from '../../../../components/lists/search-list';
import { ListItem } from '../../../../components/lists/list-item';
import { ActivationStatus } from '../../../../enums';

const pageSize = 6;

export function EditPeopleLayout({ peopleToEdit, setPeopleToEdit, setPersonToEdit, allowManagerReview, allowPeerReview, onBack, onDone }: IEditPeopleLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(false);
  const [request, setRequest] = useState<BaseSearchRequest>({
    companySlug: companyVariables.slug,
    page: 1,
    pageSize,
    sortAscending: true,
  });
  const [people, setPeople] = useState<EmployeeSearchResponse>();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const response = await actions.searchPeople(request);
      setPeople(response);
      setLoading(false);
    }

    getData();
  }, [request]);

  function generateChips(person: IPerson): IChip[] {
    let chips: IChip[] = [
      {
        label: `${person.skills.length} skill(s)`,
        backgroundColour: 'g',
      },
    ];

    if (person.selected) {
      if (allowManagerReview && !allowPeerReview && person.manager) {
        chips = [];
      }
      if (person.manager) {
        chips.unshift({
          label: 'Manager',
          backgroundColour: 'baby-blue',
        });
      }
    }

    return chips;
  }

  return (
    <div className="panelContent">
      <FocusPanelHeader supertitle="Assessments" title="Add People" subtitle="Search for and add people to the assessment" divider />

      <div className="card" style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
        <SearchList
          loading={loading}
          onInput={search => setRequest(currentState => ({ ...currentState, search, page: 1 }))}
          paging={{
            activePageNumber: request.page ?? 1,
            pageCount: people?.totalPages ?? 0,
            onPageChange: page => setRequest(currentState => ({ ...currentState, page })),
          }}
        >
          {people?.employees.map(employee => {
            const person: IPerson = peopleToEdit.find(personToEdit => personToEdit.id === employee.id) ?? {
              ...employee,
              selected: false,
              manager: false,
              skills: [],
            };

            return (
              <ListItem
                key={person.id.toString()}
                selected={person.selected}
                disabled={person.activationStatus === ActivationStatus.Archived}
                onClick={() => {
                  if (person.selected) {
                    setPeopleToEdit(currentState => {
                      if (!currentState) return;
                      let nextState: IPerson[] = structuredClone(currentState);
                      nextState = nextState.filter(selectedPerson => selectedPerson.id !== person.id);
                      return nextState;
                    });
                  } else {
                    const nextPerson = { ...person, selected: true };
                    setPeopleToEdit(currentState => {
                      if (!currentState) return;
                      const nextState: IPerson[] = structuredClone(currentState);
                      nextState.push(nextPerson);
                      return nextState;
                    });
                    setPersonToEdit && setPersonToEdit(person);
                  }
                }}
              >
                <div style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: '1fr 1fr 1fr' }}>
                  <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                    {person.firstName} {person.lastName}
                  </div>

                  <div className="caption2">{person.email}</div>

                  <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 15 }}>
                    {generateChips(person).map(chip => (
                      <Chip key={chip.label} {...chip} />
                    ))}
                  </div>
                </div>
              </ListItem>
            );
          })}
        </SearchList>
      </div>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Back</Anchor>

        <Button type="button" onClick={() => onDone()}>
          Done
        </Button>
      </footer>
    </div>
  );
}
