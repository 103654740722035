import React, { useEffect, useState } from 'react';
import { DropdownItem, capitalCase } from '@keplerco/core';
import { MappingType, OrganizationLevelType } from '../../../../../enums';
import { UniqueEntity } from '../../../../../models';
import { useAppActions, useAppState } from '../../../../../overmind';
import { ICourseMappingCourseConditionProps } from '../course-mapping-course.models';
import { CompanyEntitySearchParams } from '../../../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../../../library/helpers/permissions/extract-highest-organization-level';
import { EntitiesCardWidget } from '../../../../../components/cards/entities-card.widget';
import { MAJOR_LANGUAGES } from '../../../../../library/consts/languages';
import { DisplayList } from '../../../../../components/lists/display-list';
import { ListItem } from '../../../../../components/lists/list-item';

function getMappingTypeString(type: MappingType): string {
  switch (type) {
    case MappingType.Language: {
      return 'language';
    }

    case MappingType.Team: {
      return 'team';
    }

    case MappingType.Department: {
      return 'department';
    }

    case MappingType.Role: {
      return 'role';
    }

    default: {
      return 'entity';
    }
  }
}

export function CourseMappingCourseCondition({ type, entities, onClickAddEntity, onClickRemoveEntity }: ICourseMappingCourseConditionProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();

  function onClickEntityItem(selected: boolean | undefined, entity: UniqueEntity) {
    selected ? onClickRemoveEntity(type, entity.slug!) : onClickAddEntity(type, entity);
  }

  const [entityItems, setEntityItems] = useState<DropdownItem[]>([]);

  useEffect(() => {
    async function fetchEntityItems() {
      switch (type) {
        case MappingType.Role: {
          const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement.organizationLevels);

          const rolesRequest: CompanyEntitySearchParams = {
            organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
            companySlug: companyVariables.slug,
            departmentSlug: undefined,
            teamSlug: undefined,
            learnerSlug: undefined,
            searchGlobally: false,
            page: 1,
            pageSize: 99999, // ridiculously large pageSize to fetch ALL roles
            search: undefined,
            sortAscending: undefined,
          };

          const roles = await actions.getCompanyRoles(rolesRequest);
          setEntityItems(
            roles?.entities.map(role => {
              const selected = entities?.some(entity => entity.slug === role.slug);
              return {
                value: role.slug,
                label: role.name,
                onClick: () => onClickEntityItem(selected, { slug: role.slug, name: role.name }),
                selected,
              } as DropdownItem;
            }) ?? []
          );
          break;
        }
        case MappingType.Department: {
          const departments = await actions.getLearningManagementDepartments(companyVariables.slug!);
          setEntityItems(
            departments?.map(department => {
              const selected = entities?.some(entity => entity.slug === department.slug);
              return {
                value: department.slug,
                label: department.name,
                onClick: () => onClickEntityItem(selected, { slug: department.slug, name: department.name }),
                selected,
              } as DropdownItem;
            }) ?? []
          );
          break;
        }

        case MappingType.Team: {
          const teams = await actions.getLearningManagementTeams(companyVariables.slug!);
          setEntityItems(
            teams?.map(team => {
              const selected = entities?.some(entity => entity.slug === team.slug);
              return {
                value: team.slug,
                label: team.teamName,
                onClick: () => onClickEntityItem(selected, { slug: team.slug, name: team.teamName }),
                selected,
              } as DropdownItem;
            }) ?? []
          );
          break;
        }

        case MappingType.Language: {
          setEntityItems(
            MAJOR_LANGUAGES.map(language => {
              const selected = entities?.some(entity => entity.slug === language);
              return { value: language, onClick: () => onClickEntityItem(selected, { slug: language, name: language }), selected } as DropdownItem;
            })
          );
          break;
        }

        default: {
          break;
        }
      }
    }

    fetchEntityItems();
  }, [type, entities]);

  return (
    <EntitiesCardWidget title={`${capitalCase(getMappingTypeString(type))}s`}>
      <DisplayList dropdownItems={entityItems}>
        {entities?.map(entity => (
          <ListItem key={entity.slug} onClick={() => onClickRemoveEntity(type, entity.slug!)}>
            <div className="caption1" style={{ color: 'var(--accent-2)' }}>
              {entity.name}
            </div>
          </ListItem>
        ))}
      </DisplayList>
    </EntitiesCardWidget>
  );
}
