import React, { useEffect, useState } from 'react';
import { FetchType, MappingType } from '../../../../enums';
import { useAppActions, useAppState } from '../../../../overmind';
import { useParams } from 'react-router';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Anchor, Button, GridLayout, Modal, PageHeader, RadioButtonCard, useMatchScreenWidth } from '@keplerco/core';
import { CourseMappingResponse } from '../../../../models/overmind/learning-management';
import styles from './course-mapping-course.module.css';
import { UniqueEntity } from '../../../../models';
import { CourseMappingCourseCondition } from './course-mapping-course-conditions/course-mapping-course-condition';
import classNames from 'classnames';
import { ConfirmationModalLayout } from '../../../../widgets/layouts';
import { CourseMappingCourseSkillCondition } from './course-mapping-course-conditions/course-mapping-course-skill-condition';
import { PageLoaderLayer } from '../../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { useKeplerNavigate } from '../../../../navigation/guards/use-kepler-navigate';
import { uniqueEntityArraysUnchanged, numberArraysUnchanged } from './course-mapping-course.helpers';
import { CourseMappingCourseSkeleton } from './course-mapping-course.skeleton';
import { SkillEntity } from './course-mapping-course.models';

export function CourseMappingCoursePage(): JSX.Element {
  const { courseSlug } = useParams();

  const { companyVariables } = useAppState();
  const actions = useAppActions();

  const keplerNavigate = useKeplerNavigate();

  const isMobile = useMatchScreenWidth('mobile');

  const [currentMapping, setCurrentMapping] = useState<CourseMappingResponse>();
  const [nextMapping, setNextMapping] = useState<CourseMappingResponse>();

  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData(applyLoader: boolean = true) {
      if (applyLoader) actions.startLoader({ path: PagePath.learningManagementCourseMappingCourse, type: FetchType.PageFetching });
      const tempData = await actions.getCourseMapping({ companySlug: companyVariables.slug!, courseSlug: courseSlug! });

      if (!tempData) {
        onClickCancel();
        if (applyLoader) actions.stopLoader(PagePath.learningManagementCourseMappingCourse);
        return;
      }

      setCurrentMapping(tempData);
      setNextMapping(tempData);

      if (applyLoader) actions.stopLoader(PagePath.learningManagementCourseMappingCourse);
    }

    fetchData(true);
  }, [courseSlug]);

  function onChangeRadioButtonCard(value: boolean) {
    setNextMapping(currentState => {
      const nextState: CourseMappingResponse | undefined = structuredClone(currentState);
      if (!!nextState) nextState.lessonOrderLocked = value;
      return nextState;
    });
  }

  function onClickAddSkill(skill: SkillEntity) {
    setNextMapping(currentState => {
      const nextState: CourseMappingResponse | undefined = structuredClone(currentState);
      if (!!nextState) {
        !!nextState?.skills ? nextState.skills.push(skill) : nextState.skills = [skill];
      }
      return nextState;
    });
  }

  function onClickRemoveSkill(skillSlug: string) {
    setNextMapping(currentState => {
      const nextState: CourseMappingResponse | undefined = structuredClone(currentState);
      if (!!nextState?.skills) {
        const nextSkills = nextState.skills.filter(skill => skill.slug !== skillSlug);
        nextState.skills = !!nextSkills.length ? nextSkills : undefined;
      }
      return nextState;
    });
  }

  function onClickAddLevel(skillSlug: string, level: number) {
    setNextMapping(currentState => {
      const nextState: CourseMappingResponse | undefined = structuredClone(currentState);
      if (!!nextState?.skills) {
        const skill = nextState.skills.find(skill => skill.slug === skillSlug);
        if (!!skill) !!skill.levels ? skill.levels.push(level) : (skill.levels = [level]);
      }
      return nextState;
    });
  }

  function onClickRemoveLevel(skillSlug: string, level: number) {
    setNextMapping(currentState => {
      const nextState: CourseMappingResponse | undefined = structuredClone(currentState);
      if (!!nextState?.skills) {
        const skill = nextState.skills.find(skill => skill.slug === skillSlug);
        if (!!skill?.levels) {
          const nextLevels = skill.levels.filter(temp => temp !== level);
          skill.levels = !!nextLevels.length ? nextLevels : undefined;
        }
      }
      return nextState;
    });
  }

  function onClickAddEntity(type: MappingType, entity: UniqueEntity) {
    setNextMapping(currentState => {
      const nextState: CourseMappingResponse | undefined = structuredClone(currentState);
      if (!!nextState)
        switch (type) {
          case MappingType.Language: {
            !!nextState.languages ? nextState.languages.push(entity) : nextState.languages = [entity];
            break;
          }

          case MappingType.Team: {
            !!nextState.teams ? nextState.teams.push(entity) : nextState.teams = [entity];
            break;
          }

          case MappingType.Department: {
            !!nextState.departments ? nextState.departments.push(entity) : nextState.departments = [entity];
            break;
          }
          case MappingType.Role: {
            !!nextState.roles ? nextState.roles.push(entity) : (nextState.roles = [entity]);
            break;
          }

          default: {
            break;
          }
        }

      return nextState;
    });
  }

  function onClickRemoveEntity(type: MappingType, entitySlug: string) {
    setNextMapping(currentState => {
      const nextState: CourseMappingResponse | undefined = structuredClone(currentState);
      if (!!nextState)
        switch (type) {
          case MappingType.Language: {
            if (!!nextState.languages) {
              const nextLanguages = nextState.languages.filter(language => language.slug !== entitySlug);
              nextState.languages = !!nextLanguages.length ? nextLanguages : undefined;
            }
            break;
          }

          case MappingType.Team: {
            if (!!nextState.teams) {
              const nextTeams = nextState.teams.filter(team => team.slug !== entitySlug);
              nextState.teams = !!nextTeams.length ? nextTeams : undefined;
            }
            break;
          }

          case MappingType.Department: {
            if (!!nextState.departments) {
              const nextDepartments = nextState.departments.filter(department => department.slug !== entitySlug);
              nextState.departments = !!nextDepartments.length ? nextDepartments : undefined;
            }
            break;
          }

          case MappingType.Role: {
            if (!!nextState.roles) {
              const nextRoles = nextState.roles.filter(role => role.slug !== entitySlug);
              nextState.roles = !!nextRoles.length ? nextRoles : undefined;
            }
            break;
          }

          default: {
            break;
          }
        }

      return nextState;
    });
  }

  function hasChangedCourseMapping() {
    if (
      currentMapping?.lessonOrderLocked !== nextMapping?.lessonOrderLocked ||
      !uniqueEntityArraysUnchanged(currentMapping?.skills, nextMapping?.skills) ||
      !uniqueEntityArraysUnchanged(currentMapping?.departments, nextMapping?.departments) ||
      !uniqueEntityArraysUnchanged(currentMapping?.teams, nextMapping?.teams) ||
      !uniqueEntityArraysUnchanged(currentMapping?.languages, nextMapping?.languages) ||
      !uniqueEntityArraysUnchanged(currentMapping?.roles, nextMapping?.roles)
    ) {
      return true;
    }

    for (const currentSkill of currentMapping?.skills ?? []) {
      const nextSkill = nextMapping?.skills?.find(skill => skill.slug === currentSkill.slug);
      if (!numberArraysUnchanged(currentSkill?.levels, nextSkill?.levels)) return true;
    }

    return false;
  }

  async function onClickSubmitHandler() {
    setIsFormDirty(true);
    if (!companyVariables.slug || !courseSlug || !nextMapping) return;

    actions.startLoader({ path: PagePath.learningManagementCourseMappingCourse, type: FetchType.Sending });
    await actions.saveCourseMapping({ companySlug: companyVariables.slug!, courseSlug, request: nextMapping });

    keplerNavigate(`${PagePath.learningManagementBase}${PagePath.learningManagementCourseMapping.replace(':companySlug', companyVariables.slug!)}`);

    actions.stopLoader(PagePath.learningManagementCourseMappingCourse);
  }

  function onClickCancel() {
    keplerNavigate(`${PagePath.learningManagementBase}${PagePath.learningManagementCourseMapping.replace(':companySlug', companyVariables.slug!)}`);
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.learningManagementCourseMappingCourse} skeletonLoader={<CourseMappingCourseSkeleton />}>
        <div className="wrapper stack">
          {!!currentMapping?.image ? (
            <PageHeader
              breadcrumbs={[
                {
                  name: 'Learning Management',
                  url: PagePath.learningManagementBase,
                },
                {
                  name: `${currentMapping?.name.substring(0, 15)}...`,
                  url: window.location.pathname,
                },
              ]}
              title={currentMapping?.name}
              subtitle={currentMapping?.learningPlatform}
              type="image"
            >
              <div className={classNames('headerImage', styles.headerImage)}>
                <img src={currentMapping.image} alt={currentMapping?.name} />

                <div>
                  {currentMapping.description.length > 600 ? `${currentMapping.description.substring(0, isMobile ? 300 : 600)}...` : (currentMapping?.description ?? '')}
                </div>
              </div>
            </PageHeader>
          ) : (
            <PageHeader
              breadcrumbs={[
                {
                  name: 'Learning Management',
                  url: PagePath.learningManagementBase,
                },
                {
                  name: `${currentMapping?.name.substring(0, 15)}...`,
                  url: window.location.pathname,
                },
              ]}
              title={currentMapping?.name}
              subtitle={currentMapping?.learningPlatform}
            />
          )}

          <div className={classNames(styles.bodyLayout)}>
            <div>
              <h5 className="heading5" style={{ marginBottom: 10 }}>
                Do activities in this course need to be completed in order?
              </h5>

              <GridLayout columnCount={isMobile ? 1 : 2}>
                <RadioButtonCard id="yes" value="Yes" checked={nextMapping?.lessonOrderLocked} onChange={() => onChangeRadioButtonCard(true)}>
                  <div className="card">Yes, lock the order</div>
                </RadioButtonCard>
                <RadioButtonCard id="no" value="No" checked={!nextMapping?.lessonOrderLocked} onChange={() => onChangeRadioButtonCard(false)}>
                  <div className="card">No, don't lock the order</div>
                </RadioButtonCard>
              </GridLayout>
            </div>


            <CourseMappingCourseSkillCondition
              skills={nextMapping?.skills}
              isFormDirty={isFormDirty}
              onClickAddSkill={onClickAddSkill}
              onClickRemoveSkill={onClickRemoveSkill}
              onClickAddLevel={onClickAddLevel}
              onClickRemoveLevel={onClickRemoveLevel}
            />

            <CourseMappingCourseCondition
              type={MappingType.Role}
              entities={nextMapping?.roles}
              onClickAddEntity={onClickAddEntity}
              onClickRemoveEntity={onClickRemoveEntity}
            />

            <CourseMappingCourseCondition
              type={MappingType.Department}
              entities={nextMapping?.departments}
              onClickAddEntity={onClickAddEntity}
              onClickRemoveEntity={onClickRemoveEntity}
            />

            <CourseMappingCourseCondition
              type={MappingType.Team}
              entities={nextMapping?.teams}
              onClickAddEntity={onClickAddEntity}
              onClickRemoveEntity={onClickRemoveEntity}
            />

            <CourseMappingCourseCondition
              type={MappingType.Language}
              entities={nextMapping?.languages}
              onClickAddEntity={onClickAddEntity}
              onClickRemoveEntity={onClickRemoveEntity}
            />

            <div className={styles.buttons}>
              <Anchor onClick={() => hasChangedCourseMapping() ? setIsOpen(true) : onClickCancel()}>Cancel</Anchor>

              <Button type="button" disabled={!hasChangedCourseMapping()} onClick={onClickSubmitHandler}>
                Submit
              </Button>
            </div>
          </div>

        </div>
      </PageLoaderLayer>

      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <ConfirmationModalLayout
          titleSize="small"
          title="There are unsaved changes."
          subtitleSize="large"
          subtitle="Are you sure you want to cancel?"
          submitButtonText="Yes"
          onClickSubmit={onClickCancel}
          cancelButtonText="No"
          onClickCancel={() => setIsOpen(false)}
        />
      </Modal>
    </React.Fragment>
  );
}

