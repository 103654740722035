import React, { useEffect, useState } from 'react';
import { DropdownItem } from '@keplerco/core';
import { useAppActions, useAppState } from '../../../../overmind';
import { OrganizationLevelType, SortField } from '../../../../enums';
import { CompanyEntityAssigneeListItemResponse } from '../../../../models/overmind/company-entity';
import { CompanyRoleAssigneesSearchParams } from '../../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../../library/helpers/permissions/extract-highest-organization-level';
import { IManagePeopleWidgetProps } from './manage-role.models';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { useParams } from 'react-router-dom';
import { DisplayList } from '../../../../components/lists/display-list';
import { ListItem } from '../../../../components/lists/list-item';

export function ManagePeopleWidget({ loadingPeople, setLoadingPeople, setPeopleToAssign, setPeopleToUnassign }: IManagePeopleWidgetProps): JSX.Element {
  const { roleSlug } = useParams();

  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();

  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);
  const [people, setPeople] = useState<CompanyEntityAssigneeListItemResponse[]>([]);

  useEffect(() => {
    async function getPeople(): Promise<CompanyEntityAssigneeListItemResponse[]> {
      const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement?.organizationLevels);
      const request: CompanyRoleAssigneesSearchParams = {
        search: undefined,
        sortAscending: true,
        sortField: SortField.Name,
        pageSize: 99999, // ridiculously high page size to fetch ALL Assignees
        page: 1,
        organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        roleSlug: roleSlug!,
      };
      const response = await actions.getCompanyRoleAssignees(request);
      setPeople(response?.assignees ?? []);
      return response?.assignees ?? [];
    }

    function onClickDropdownItem(employee: CompanyEntityAssigneeListItemResponse) {
      setDropdownItems(currentState => {
        const nextState = currentState.map(item => ({ ...item }));
        const item = nextState.find(temp => temp.value === employee.slug);
        if (item) {
          item.selected ? removePerson(employee) : addPerson(employee);
          item.selected = !item.selected;
        }
        return nextState;
      });
    }

    async function getDropdownItems(people: CompanyEntityAssigneeListItemResponse[]) {
      const response = await actions.searchPeople({ companySlug: companyVariables.slug!, pageSize: 99999, page: 1 });
      const items: DropdownItem[] = response?.employees.map(person => ({
        value: person.learnerSlug!,
        label: `${person.firstName} ${person.lastName}`,
        onClick: () => onClickDropdownItem({
          slug: person.learnerSlug,
          name: `${person.firstName} ${person.lastName}`,
          department: person.department.name,
          team: person.team.name,
          email: person.email,
        }),
        selected: people.some(assignee => assignee.slug === person.learnerSlug),
      })) ?? [];
      setDropdownItems(items);
    }

    async function getData() {
      setLoadingPeople(true);

      if (!roleSlug) {
        await getDropdownItems([]);
        setLoadingPeople(false);
        return;
      }

      const response = await getPeople();
      await getDropdownItems(response);
      setLoadingPeople(false);
    }

    getData();
  }, [roleSlug]);

  async function addPerson(person: CompanyEntityAssigneeListItemResponse) {
    setPeople(currentState => (!currentState.some(item => item.slug === person.slug) ? [...currentState, person] : currentState));
    setPeopleToAssign(currentState => [...currentState, person]);
    setPeopleToUnassign(currentState => currentState.filter(temp => temp.slug !== person.slug));
  }

  async function removePerson(person: CompanyEntityAssigneeListItemResponse): Promise<CompanyEntityAssigneeListItemResponse[]> {
    let nextPeople: CompanyEntityAssigneeListItemResponse[] = [];
    setPeople(currentState => {
      nextPeople = currentState.filter(temp => temp.slug !== person.slug);
      return nextPeople;
    });
    setPeopleToAssign(currentState => currentState.filter(temp => temp.slug !== person.slug));
    setPeopleToUnassign(currentState => [...currentState, person]);
    return nextPeople;
  }

  if (loadingPeople) {
    return (
      <div>
        <SkeletonLoaderRow style={{ marginBottom: 15 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="43px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="400px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>
      </div>
    );
  }

  return (
    <DisplayList loading={loadingPeople} dropdownItems={dropdownItems}>
      {people.map(person => (
        <ListItem
          key={person.slug}
          onClick={async () => {
            const response = await removePerson(person);
            setDropdownItems(currentState => currentState.map(item => ({ ...item, selected: response.some(person => person.slug === item.value) })));
          }}
        >
          <div style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: '1fr 1fr' }}>
            <div className="caption1" style={{ color: 'var(--accent-2)' }}>
              {person.name}
            </div>

            <div className="caption2">{person.email}</div>
          </div>
        </ListItem>
      ))}
    </DisplayList>
  );
}
